// 引入api
import { fun_UploadImg, fun_PostAction } from "@/api";
// 引入加密方法
import "@/utils/security.js";
// 默认导出
export default {
  // 数据存储对象
  data: () => {
    return {
      // 抽屉状态
      drawerStatus: false,
      // 抽屉确定按钮loading状态
      loading: false,
      // 查询表单字段
      form: {
        clientName: null, // 应用名称
        versionNumber: null, // 版本号
        number: "0", //
        remarks: null, //备注
        file: null // 多文件上传
      },
      // 多文件上传
      fileList: [],
      // 表单提交返回的错误消息
      errRules: {
        clientName: null, // 应用名称
        versionNumber: null, // 版本号
        remarks: null, //备注
        file: null // 多文件上传
      },
      // 详情表单基础数据
      formConfigList: [
        {
          label: "应用名称：",
          value: "clientName",
          type: "input",
          required: true,
          rule: {
            required: true,
            message: "请输入应用名称",
            trigger: "blur"
          }
        },
        {
          label: "版本号：",
          value: "versionNumber",
          type: "input",
          required: true,
          rule: {
            required: true,
            message: "请输入版本号",
            trigger: "blur"
          }
        },
        {
          label: "是否增量：",
          value: "number",
          type: "radio",
          required: true,
          option: [
            {
              label: "增量",
              value: "0"
            },
            {
              label: "全量",
              value: "1"
            },
          ],
          rule: {
            required: true,
            message: "请选择文件序号",
            trigger: "change"
          }
        },
        {
          label: "备注：",
          value: "remarks",
          type: "textarea",
          required: true,
          maxlength:300,
          rule: {
            required: true,
            message: "请输入备注",
            trigger: "blur"
          }
        },
        {
          label: "单文件上传：",
          value: "file",
          type: "slot",
          slotName: "file",
          required: true,
          rule: {
            required: true,
            message: "请上传文件",
            trigger: "change"
          }
        }
      ]
    };
  },

  // 自定义方法
  methods: {
    /**
     * 表单提交
     * @author 滕威
     */
    fun_SubmitForm() {
      if (this.fileList.length > 1) {
        // 请求失败，提示信息
        this.$message.error("只能上传一个文件！请重新上传！");
        return false;
      }
      // 校验
      this.$refs.form.$refs.enInform.validate(valid => {
        // 校验通过
        if (valid) {
          // 创建FormData
          let formData = new FormData();
          // 表单文件列表中的raw取出
          this.fileList.map(item => {
            // 将文件放入formData
            formData.append("files", item.raw);
          });
          // 遍历表单
          for (const key in this.form) {
            // 放入FormData中
            formData.append(key, this.form[key]);
          }
          // 打开确定按钮loading状态
          this.loading = true;
          // 发起请求，获取结果集
          fun_UploadImg("/clientFileInfo/saveOrUpdate", formData,0)
            .then(res => {
              // 解构res.data
              const { result, msg, data } = res.data;
              // 判断请求是否成功
              if (result) {
                // 请求一览查询接口
                this.$parent.fun_GetList();
                // 关闭弹窗
                this.drawerStatus = false;
                // 提示信息
                this.$message.success(msg);
              } else {
                // 如果存在返回数据
                if (data != null) {
                  // 定义rules为一个随机数
                  this.errRules = Math.random();
                  // 执行延迟方法
                  this.$nextTick(() => {
                    // 提示
                    this.$message.error("请按提示修改");
                    // 赋值错误消息
                    this.errRules = data;
                  });
                } else {
                  // 请求失败，提示信息
                  this.$message.error(msg);
                }
              }
              // 取消确定按钮loading状态
              this.loading = false;
            })
            .catch(() => {
              // 取消确定按钮loading状态
              this.loading = false;
            });
        } else {
          // 校验不通过，返回false，页面给出提示
          return false;
        }
      });
    },
    /**
     * 抽屉关闭时执行
     * @author 滕威
     */
    fun_DrawerClose() {
      // 清除表单验证和数据
      this.$refs.form.$refs.enInform.resetFields();
      // 清空file
      this.form.file = null;
      // 清空fileList
      this.fileList = [];
    },
    /**
     * 文件状态改变时的钩子
     * @param {file} 当前被改变的文件
     * @param {fileList} 当前文件列表中的文件
     * @author 滕威
     */
    handleChange(file, fileList) {
      // 判断文件列表中是否有文件
      if (fileList.length === 1) {
        // 如果有文件
        // 将文件放入表单中
        this.fileList = this.form.file = fileList;
        // 清除上传文件验证信息
        this.$refs.form.$refs.enInform.clearValidate("file");
      }
    },
    /**
     * 文件列表移除文件时的钩子
     * @param {file} 当前被移除的文件
     * @param {fileList} 当前文件列表中的文件
     * @author 滕威
     */
    handleRemove(file, fileList) {
      // 将文件放入表单中
      this.fileList = this.form.file = fileList;
      // 如果当前文件列表为空
      if (fileList.length < 1) {
        // 表单文件也置空
        this.fileList = this.form.file = null;
      }
    },
    /**
     * 文件移除之前
     * @param {file} 当前被移除的文件
     * @author 滕威
     */
    beforeRemove(file) {
      return this.$confirm(`确定移除 ${file.name}？`);
    }
  }
};
